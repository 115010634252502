/**
 * Timetastic
 * Pricing Quote component
 */
import React from "react"
import PropTypes from "prop-types"

const PricingQuote = ({ avatar, company, name, quote }) => {
  return (
    <div className="c-pricing-quote u-text-center">
      <img loading="lazy" alt={name} className="c-pricing-quote__avatar" src={avatar} />
      <div className="c-pricing-quote__head">
        <p>{quote}</p>
      </div>
      <div className="c-pricing-quote__body">
        <div className="c-pricing-quote__person">
          {name}, {company}
        </div>
      </div>
    </div>
  )
}

PricingQuote.propTypes = {
  avatar: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  quote: PropTypes.string.isRequired,
}

export default PricingQuote
